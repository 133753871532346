.in-page-nav-wrap {
    display: flex;
    // justify-content: start;
    justify-content: space-between;
    
    &.nav-gallery-grid{
        justify-content: center;
        .in-page-nav{
            @include breakpoint(S) {
                padding: 0 1rem;
                a{
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
    @include breakpoint(S) {
        width: 100%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        .in-page-nav{
            order: 2;
            padding: 0;
            &.post-share-blog{
                order: 1;
                margin-bottom: 2.18rem;
                .button{
                    svg{
                        width: 1.563rem;
                        height: 1.563rem;
                    }
                }
            }
        }
    }
    .post-share-blog{
        align-items: center;
        .button{
            margin: 0;
            opacity: 1;
            svg{
                opacity: 1;
                @include color(color, 'color-headings');
            }
        }
    }
}

.in-page-nav {
    max-width: 100%;

    @include breakpoint(S) {
        flex-wrap: nowrap;
        padding-left: 1rem;
        justify-content: space-between;
        width: 100%;
    }
    a {
        @include color(color, 'body');
        padding: 0.5rem 0;
        margin: 0 1rem;
        font-weight: bold;
        flex-shrink: 0;
        min-width: 2rem;
        opacity: 0.25;
        @include breakpoint(S) {
            margin: 0 .5rem;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 1px;
            @include color(background-color, 'accent-primary');
            @extend %transition;
        }
        &.active,
        &:hover {
            opacity: 1;
            @include color(color, 'accent-primary');

            &:after {
                width: 100%;
            }
        }
        @include breakpoint(S) {
            &:last-child {
                padding-right: 2rem;

                &:after {
                    left: calc(50% - 1rem);
                }
                &.active,
                &:hover {
                    &:after {
                        width: calc(100% - 2rem);
                    }
                }
            }
        }
        @include breakpoint(XS) {
            @include size-XS;
            margin: 0 0.75rem;
        }
    }
}