.wp-block-image {
    max-width: 50rem;
    margin: 2rem auto 2rem auto;

    img {
        max-width: 100%;
    }
    figcaption {
        @include color(color, 'body-50');
        @include size-S;
    }
}