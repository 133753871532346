//Form Styling

form {
    //Set vertical rhythm for all child elements of a form
    > * {
        margin-bottom: 1.5rem;

        &.no-margin {
            margin-bottom: 0;
        }
        //Remove margin-bottom for last element
        &:last-child {
            margin-bottom: 0;
        }
    }
    .row {
        display: flex;
        margin-left: -1rem;
        margin-right: -1rem;

        @include breakpoint(XS) {
            flex-direction: column;
            margin: 0;
        }
    }
    .full-width {
        padding: 0 1rem;
        width: 100%;

        @include breakpoint(XS) {
            padding: 0;
            margin-bottom: 1rem;
        }
    }
    .half {
        width: 50%;
        margin: 0;
        padding: 0 1rem;

        @include breakpoint(XS) {
            width: 100%;
            padding: 0;
            margin-bottom: 1rem;
        }
    }
}

//Label Styling
label {
    font-weight: 700;
    @include size-XS;
    font-family: $font-stack-headings;
    @extend %transition;
    @include color(color, 'body');

    //Apply margin between labels and inputs
    input,
    textarea,
    .dropdown {
        margin-top: $unit;
    } 

    //Active styling
    &.active {
        @include color(color, 'accent-primary');
    }
}

//Standard Input Styling

input,
textarea,
.dropdown {
    //Resets
    display: block;
    box-shadow: none;
    -webkit-appearance: none;
    position: relative;
    z-index: 2;
    background: #fff;
    color: $color-body;

    border: solid 1px;
    @include color(border-color, 'body');

    font-family: $font-stack-primary;
    @include size-M;

    padding: 0.888rem;
    width: 100%;

    @extend %transition;

    //Focus State
    &:focus,
    &:hover {
        @include color(border-color, 'accent-primary');
        outline: 0;
    }

    @include placeholder {
        @include color(color, 'body');
        opacity: 0.5;
    }
    &[type=text] {
        appearance: none;
    }
    &.wpcf7-not-valid {
        @include color(color, 'error');
    }
    @include breakpoint(XS) {
        font-size: 16px;
    }
}

.input-wrap,
.dropdown {
    position: relative;

    label {
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        left: calc(0.888rem + 2px);
    }
    input,
    textarea {
        padding: 1.5rem 0.888rem 0.5rem 0.888rem;
        margin: 0;

        &:focus {
            outline: 0;

            + label {
                @include color(color, 'accent-primary');
            }
        }
    }
    textarea {
        height: 10rem;
    }
    &.label-active {
        label {
            @include color(color, 'accent-primary');
        }
    }
    &.datepicker {
        cursor: pointer;
        border-radius: 0;

        input {
            cursor: pointer;
        }
    }
}

.wpcf7 {
    padding-top: 3.5rem;

    form div.wpcf7-response-output {
        margin: 0 0 2rem 0;
        border: 0;
        clear: both;
        @include color(color, 'background');
        padding: 1rem;
        @include color(background-color, 'accent-primary');
        position: relative;
        text-align: center;

        &.wpcf7-validation-errors {
            @include color(background-color, 'error');
        }
    }
    input[type="submit"] {
        margin-top: 2rem;
    }
}

.wpcf7-not-valid-tip {
    @include size-S;
}

.ajax-loader {
    display: block;
}

div.wpcf7 .ajax-loader {
    display: block;
    margin: 0 auto;
}

.styled-form{
    form{
        .gform_body{
            .gform_fields{
                .gfield{
                    background-color: #fff;
                    border-radius: 16px;
                    border: 1px solid #fff;
                    padding: 0.75rem 1rem;
                    legend,
                    label{
                        margin: 0;
                        @include size-XS;
                        font-family: $font-stack-headings;
                        font-weight: 700;
                        padding-left: 0;
                        color: $color-headings;
                    }
                    input,
                    .dropdown,
                    select,
                    textarea{
                        padding: 0;
                        border: none;
                        @include color(color, 'background');
                        &::placeholder{
                            @include color(color, 'background-50');
                        }
                    }
                    .dropdown{
                        padding: 0;
                        [dir="rtl"] & {
                            select{
                                padding-right: 40px;
                            }
                        }
                    }
                    [dir="rtl"] & {
                        text-align: right;
                    }
                    &:has(.ginput_recaptcha){
                        display: none;
                    }
                }
                fieldset{
                    background-color: transparent !important;
                    border: none !important;
                    .ginput_container_checkbox{
                        .gfield_checkbox{
                            .gchoice{
                                label{
                                    @include size-XS;
                                    @include color(color, 'body');
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
            #field_1_7{
                background-color: transparent;
                border: none;
            }
        }
        .gform_footer{
            position: relative;
            width: fit-content;
            input[type="submit"]{
                @include color(color, 'body');
                border-color: transparent;
                position: relative;
                background: unset;
                background-color: transparent;
                padding-right: 3rem;
                padding-left: 0;
                margin: .5rem .5rem .5rem 0;
                
                &:hover {
                    background-color: transparent !important;
                    @include color(color, 'accent-secondary');
                }
            }
            &::before{
                content: "";
                width: 3rem;
                height: 3rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin-right: -.5rem;
                right: 0rem;
                -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2734_1682)'%3E%3Cpath d='M21.1581 18.3149L26.8421 23.9999L21.1581 29.6849' stroke='%23003594' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Crect x='0.500061' y='0.5' width='47' height='47' rx='23.5' stroke='%23003594'/%3E%3Cdefs%3E%3CclipPath id='clip0_2734_1682'%3E%3Crect x='12.0001' y='12' width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                mask-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2734_1682)'%3E%3Cpath d='M21.1581 18.3149L26.8421 23.9999L21.1581 29.6849' stroke='%23003594' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Crect x='0.500061' y='0.5' width='47' height='47' rx='23.5' stroke='%23003594'/%3E%3Cdefs%3E%3CclipPath id='clip0_2734_1682'%3E%3Crect x='12.0001' y='12' width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                @include color(background-color, 'body');
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-position: center center;
                -webkit-mask-size: contain;
                opacity: 1;
                visibility: visible;
                left: unset;
                box-shadow: none;
                @extend %transition;
            }
            &:hover{
    
                &::before{
                    @extend %transition;
                    margin-right: -.7rem;
                    @include color(background-color, 'accent-secondary');
                }
            }
        }
    }
}

@import "dropdowns";
@import "checkboxes";
@import "textarea";