.copyright {
    padding: 1.125rem 0;
    // @include color(background-color, 'background');

    @include breakpoint(XS) {
        padding: 1.5rem 0;
    }
    .copyright-menu p {
        margin-right: 1.5rem;

        [dir="rtl"] & {
            margin-right: 0;
            margin-left: 1.5rem;
        }

        @include breakpoint(XS) {
            margin-right: 0;
            margin-bottom: 0.5rem;
            [dir="rtl"] & {
                margin-left: 0;
                margin-bottom: 0.5rem;
            }
        }
    }
    a {
        @include color(color, 'body');

        @include breakpoint(XS) {
            padding: 0.5rem;
        }
        &:hover {
            @include color(color, 'accent-primary');
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(XS) {
            justify-content: center;
            margin: 0;
            padding: 0.5rem 0;
        }
        li {
            @include breakpoint(XS) {
                width: 100%;
                text-align: center;
            }
            a {
                @include size-S;
                display: block;
                margin-right: 1.5rem;

                @include breakpoint(XS) {
                    margin: 0;
                    padding: 0.5rem;
                }
            }
        }
    }
}

.grecaptcha-badge {
    display: none !important;
}

.error.notice {
    display: none;
    position: fixed;
    width: 17em;
    right: 5em;
    bottom: 2em;
    z-index: 20;
    @include color(color, 'background');
    font-size: 16px;
    border-radius: 8px;
    @include color(background-color, 'background');
    border: 1px solid;
    @include color(border-color, 'body');
    
    :before {
        content: "Caching is disabled";
        display: block;
        font-weight: 700;
        @include color(color, 'body');
    }
    p {
        margin-bottom: 0em;
        padding: 2em;
    }
}

.js-category-target {
    @extend %transition;

    &.filtered {
        display: none;
    }
    &.fade-out {
        opacity: 0;
    }
}

.footer {
    clear: both; // just in case any floating elements
}

.popup-general{
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    &::before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: .6;
    }
    .popup-content{
        position: absolute;
        min-width: 60vw;
        min-height: 50vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-image: url(/wp-content/themes/dana-bay/assets/img/backgrounds/homepage-entertainment.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 88px 96px 55px 96px;
        border-radius: 8px;
        .popup-close{
            position: absolute;
            width: 1.5rem;
            height: 1.5rem;
            top: 0;
            right: 0;
            margin: 1.75rem;
            cursor: pointer;
            &::after,
            &::before{
                position: absolute;
                content: "";
                width: 1px;
                height: 100%;
                background-color: #fff;
                top: 50%;
                left: 50%;
            }
            &::after{
                transform: translate(-50%, -50%) rotateZ(45deg);
            }
            &::before{
                transform: translate(-50%, -50%) rotateZ(-45deg);
            }
        }
        @include breakpoint(S) {
            min-width: 90vw;
            padding: 5rem 3rem;
        }
    }
}