.footer-newsletter{
    background-image: url(/wp-content/themes/dana-bay/assets/img/backgrounds/newsletter.jpg);
    background-position: center;
    background-size: auto;
    background-repeat: repeat;
    padding-top: 6rem;
    padding-bottom: 6rem;
    .formulary{
        form{
            .gform_body{
                margin-bottom: 0;
                .gform_fields{
                    .gfield{
                        input[type=text],
                        input[type=tel],
                        input[type=email]{
                            border-radius: 8px;
                            padding: 1rem;
                            @include color(background-color, 'background-alt');
                            @include color(color, 'body');
                            border: 0;
                            &::placeholder{
                                @include color(color, 'body');
                            }
                        }
                        input[type=submit]{
                            font-weight: 500;
                            @include color(color, 'background');
                            @include color(background, 'color-aqua-green-gradient');
                            border-radius: 50px;
                            background-size: 100% 100%;
                            background-position:0% 0%;
                            &:hover{
                                @extend %transition;
                                @include color(color, 'background');
                                @include color(background, 'color-brand-blue-gradient');
                                background-size: 100% 100%;
                                background-position:0% 0%;
                                animation: .2s ease 1 primary-change-in;
                            }
                            @include breakpoint(XS) {
                                width: auto;
                            }
                        }
                    }
                }
            }
            .gform_footer{
                display: none;
            }
        }
    }
}
.footer {
    padding-top: 6rem;
    padding-bottom: 0;

    .social-links {
        .button {
            margin: 0 0 0 1rem;
        }

        @include breakpoint(XS) {
            margin-top: 1rem;
            .button.icon.secondary{
                margin-right: 0.1rem;
                margin-left: 0;
                svg{
                    @include color(color, 'accent-secondary');
                    width: 1.563rem;
                    height: 1.563rem;
                    &:hover{
                        @include color(color, 'body');
                    }
                }
            }
        }
    }
    @include breakpoint(S) {
        padding-top: 3.93rem;
    }
}

.footer-logo {
    @include breakpoint(S) {
        width: 100%;
    }
    svg {
        @include breakpoint(S) {
            width: 10rem;
            height: auto;
        }
    }
}

.footer-content {
    .footer-contact {
        flex-basis: 20%;
        flex-grow: 1;
        // margin-right: 3rem;
        [dir="rtl"] & {
            // margin-left: 3rem;
            margin-right: 0;
        }
        .button{
            padding: 0;
            margin: 0;
            margin-bottom: 1rem;
            svg{
                display: flex;
                align-self: flex-end;
                [dir="rtl"] & {
                    transform: rotateZ(180deg);
                }
            }
            &:hover{
                svg{
                    margin-left: .5rem;
                    path{
                        @include color(stroke, 'accent-primary');
                    }
                }
            }
        }

        @include breakpoint(XS) {
            width: 100%;
            flex-basis: 100%;
            padding-bottom: 0;
            .social-links{
                .button.icon.secondary{
                    margin-right: 0.1rem;
                    svg{
                        @include color(color, 'accent-secondary');
                        width: 1.563rem;
                        height: 1.563rem;
                        &:hover{
                            @include color(color, 'body');
                        }
                    }
                }
            }
        }
    }
}

.footer-menus {
    flex-basis: 65%;
    display: grid;
    // Basically this is saying "if there's enough space, fill in columns at comfortable width with a min-width of 16rem
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    gap: 2rem;

    @include breakpoint(XS) {
        flex-basis: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: unset;
        .footer-menu{
            margin: 0;
            flex-basis: 50%;
            width: 100%;
            display: flex;
            .footer-menu-wrap{
                .list-reset{
                    flex-direction: column;
                    li{
                        width: 100%;
                    }
                }
            }
        }
    }
}
.footer-logo{
    flex-basis: 15%;
    display: grid;
    align-content: center;
    justify-items: center;
    // Basically this is saying "if there's enough space, fill in columns at comfortable width with a min-width of 16rem
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    gap: 2rem;
    padding-bottom: 2rem;
    @include breakpoint(XS) {
        padding-bottom: 0;
        flex-basis: 100%;
        width: 100%;
        display: block;
    }
}

.footer-contact {
    a {
        @include color(color, 'body');

        &:hover {
            @include color(color, 'accent-primary');
        }
    }
    .tel,
    .email {
        display: flex;
        align-items: center;
        white-space: nowrap;
        strong{
            padding-right: .2rem;
            [dir="rtl"] & {
                padding-left: .2rem;
                padding-right: 0;
            }
        }
    }
    @include breakpoint(XS) {
        padding-top: 1rem;
        padding-bottom: 1.5rem;

        h5 {
            @include size-L;
        }
    }
}

.footer-menu {
    margin-bottom: 2rem;
    padding-right: 0;
    padding-left: 3rem;
    margin-right: -3rem;
    [dir="rtl"] & {
        padding-right: 0;
        padding-left: 3rem;
        margin-left: -3rem;
        margin-right: 0;
    }

    @include breakpoint(XS) {
        padding-right: 0;
        padding-left: 0;
        margin-bottom: 1.5rem;
    }
    .footer-menu-wrap {
        .list-reset{
            li{
                a{
                    @include size-M;
                }
            }
        }
        @include breakpoint(XS) {
            padding-bottom: 0;
        }
    }
    ul {
        width: 100%;

        @include breakpoint(XS) { 
            display: flex;
            flex-wrap: wrap;
        }
        li {
            margin-bottom: 1rem;
            padding-right: 1rem;
            [dir="rtl"] & {
                padding-left: 1rem;
                padding-right: 0;
            }

            @include breakpoint(XS) {
                width: 50%;
                margin-bottom: 0;
            }
        }
    }
    a {
        display: block;
        @include color(color, 'body');
        @include size-S;

        @include breakpoint(XS) {
            padding: .5rem 0;
        }
        &:hover {
            @include color(color, 'accent-primary');
        }
    }
}

.mob-footer-menu-toggle {
    width: 100%;

    .button.icon {
        display: none;

        @include breakpoint(XS) {
            display: flex;
        }
    }
    @include breakpoint(XS) {
        @include size-L;

        &.active {
            @include color(color, 'accent-primary');

            .button {
                @include color(border-color, 'accent-primary');

                svg {
                    @include color(color, 'accent-primary');
                }
            }
        }
    }
}

.copyright {
    padding-bottom: 2rem;
    @include breakpoint(XS) {
        padding-bottom: 3.93rem;
        .uphotel {
            padding-left: 0;
        }
        .copyright-menu {
            width: 100%;
            margin-bottom: 0.5rem;
            @include color(color, 'body');

            ul {
                display: block;
                padding: 0 0 0 0.5rem;

                li {
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }
    .copyright-menu{
        flex-basis: 85%;
        display: flex;
        @include breakpoint(XS) {
            flex-basis: 100%;
            flex-direction: column;
            align-items: baseline;
            .list-reset{
                padding: 0;
                li a{
                    padding-left: 0;
                }
            }
        }
    }
    .social-footer{
        flex-basis: 15%;
        display: flex;
        justify-content: center;
        position: relative;
        .title-social-footer{
            position: absolute;
            top: -1.12rem;
            left: 50%;
            transform: translateX(-50%);
            @include color(color, 'accent-secondary');
        }
        .button.icon.secondary{
            margin-right: 0.1rem;
            svg{
                @include color(color, 'accent-secondary');
                width: 1.563rem;
                height: 1.563rem;
                &:hover{
                    @include color(color, 'body');
                }
            }
            [dir="rtl"] & {
                margin-right: unset;
                margin-left: 0.1rem;
            }
        }

        @include breakpoint(XS) {
            display: none;
        }
    }
}